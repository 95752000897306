@import "rem";
@import "/libs/normalize.min.css";
@import "/libs/flexboxgrid.min.css";
@import "/libs/owl.carousel.min.css";
@import "/libs/jquery-ui.css";

@mixin clearfix {
	&:before,
	&:after {
		content: "";
		display: table;
	}
	&:after {
		clear: both;
	}
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin-top: 0;
}

ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

html,
body {
	height: 100%;
	min-width: 1200px;
}

body {
	font-family: open-sans, sans-serif;
	background: url('/img/bg-body.jpg');
	display: flex;
	flex-direction: column;
}

.md-wrapper {
	max-width: 1200px;
	margin: 0 auto;
}

.hide {
	display: none;
}

.shadow {
	background-color: #ffffff;
	box-shadow: 0 8px 16px 0 rgba(0,0,0,.32);
}

.owl-prev,
.owl-next {
	position: absolute;
	top: 50%;
	height: 71px;
	margin-top: -36px;
	width: 37px;
	font-size: 0;
}

.owl-prev {
	left: -25px;
	background: url('/img/slider-arrow-left.png') no-repeat;
	&:hover {
		background: url('/img/slider-arrow-left-hover.png') no-repeat;
	}
}

.owl-next {
	right: -25px;
	background: url('/img/slider-arrow-right.png') center no-repeat;
	&:hover {
		background: url('/img/slider-arrow-right-hover.png') center no-repeat;
	}
}

.owl-carousel .owl-item {
	float: none;
}

.owl-carousel .owl-wrapper {
	display: flex !important;
	align-items: center;
}

.header {
	background-color: #ffffff;
	&__top {
		padding: 16px 0 14px;
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
	}
}

.main-header {
	flex: 0 0 auto;
}

.content {
	flex: 1 0 auto;
}

.logo {
	&__link {
		display: block;
	}
	&__img {
		display: block;
		max-width: 100%;
		height: auto;
	}
}

.title {
	display: flex;
	justify-content: space-between;
	h1 {
		font-size: rem(30px);
		font-weight: 600;
		max-width: calc(560/1116*100%);
		box-sizing: border-box;
		text-transform: uppercase;
		line-height: 35px;
		margin-bottom: 0;
		margin-right: 2%;
	}
}

.search {
	width: calc(470/1200*100%);
	box-sizing: border-box;
	border: 1px solid #878787;
	border-radius: 30px;
	padding: 3px 3px 3px 20px;
	display: flex;
	justify-content: space-between;
	background-color: #ffffff;
	&__input {
		box-sizing: border-box;
		border: none;
		outline: none;
		line-height: rem(27px);
		&_submit {
			background-color: #ff0000;
			font-size: rem(14px);
			color: #ffffff;
			text-transform: uppercase;
			font-weight: 400;
			border-radius: 30px;
			padding: 0 12px 0 18px;
			cursor: pointer;
			&:hover {
				background-color: #6e6e6e;
			}
		}
		&_search {
			width: 100%;
			font-weight: 600;
			font-size: rem(16px);
			color: #000;
		}
	}
}

.callback {
	&__tel,
	&__btn {
		display: block;
		text-decoration: none;
	}
	&__tel {
		font-size: rem(20px);
		font-weight: 400;
		color: #747474;
		padding-left: 35px;
		position: relative;
		line-height: rem(22px);
		margin-bottom: 13px;
		&:before {
			content: '';
			display: block;
			position: absolute;
			left: 0;
			background: url('/img/icon-telephone.png') no-repeat;
			width: 22px;
			height: 22px;
		}
		&:hover {
			&:before {
				background: url('/img/icon-telephone-hover.png') no-repeat;
			}
		}
	}
	&__btn {
		border: 1px solid #878787;
		border-radius: 30px;
		line-height: rem(28px);
		padding: 0 26px;
		text-transform: uppercase;
		font-size: rem(14px);
		color: #ff0000;
		font-weight: 400;
		&:hover {
			border-color: #ff0000;
		}
	}
}

.nav {
	background-color: #6e6e6e;
	box-shadow: 0 6px 10px 0 rgba(0,0,0,.27);
	&__wrapper {
		display: flex;
		justify-content: space-between;
		align-items: stretch;
	}
	&__list {
		display: flex;
		flex-shrink: 0;
	}
	&__item {
		position: relative;
		&:before {
			content: '';
			position: absolute;
			right: 0;
			display: block;
			width: 1px;
			height: 100%;
			background: linear-gradient(to bottom, #ffffff 33%, #c8c8c8 66%);
		}
	}
	&__link {
		display: block;
		text-decoration: none;
		line-height: rem(42px);
		color: #ffffff;
		padding: 0 14px;
		&:hover {
			color: #000000;
			background: linear-gradient(to bottom, #ffffff 33%, #c8c8c8 66%);
		}
	}
}

.basket {
	width: calc(215/1200*100%);
	background-color: #3e3e3e;
	font-size: rem(12px);
	color: #ffffff;
	display: flex;
	flex-direction: column;
	justify-content: center;
	box-sizing: border-box;
	padding-left: 14px;
	padding-right: 50px;
	position: relative;
	&:before {
		content: '';
		display: block;
		position: absolute;
		right: 12px;
		bottom: 7px;
		width: 22px;
		height: 19px;
		background: url('/img/icon-basket.png') no-repeat;
	}
	&__title {
		text-transform: uppercase;
		font-weight: 400;
		text-align: center;
		font-size: rem(24px);
		border-bottom: 1px solid #c2c2c2;
		padding-bottom: 5px;
	}
	&__popup {
		position: absolute;
		z-index: 100;
		top: 110%;
		border: 3px solid #3e3e3e;
		width: 345px;
		box-sizing: border-box;
		padding: 8px 13px 8px 8px;
		right: 0;
		border-radius: 5px;
		background-color: #ffffff;
		display: none;
	}
	&__link {
		display: block;
		text-decoration: none;
		width: 161px;
		margin: 0 auto;
		background-color: #ff0000;
		border-radius: 5px;
		line-height: rem(34px);
		text-align: center;
		color: #ffffff;
		box-shadow: 0 2px 7px 0 rgba(0,0,0,.17);
		&:hover {
			background-color: #6e6e6e;
		}
	}
	&__list {
		max-height: 242px;
		overflow: auto;
		border-bottom: 3px solid #bbbbbb;
	}
	&__item {
		display: flex;
		justify-content: space-between;
		padding: 8px 0 6px;
		border-top: 1px solid #bbbbbb;
		&:first-child {
			border-top: none;
		}
		&-img {
			width: calc(55/345*100%);
		}
		&-title {
			width: calc(160/345*100%);
			font-weight: 300;
			font-size: rem(12px);
			color: #000000;
		}
		&-right {
			width: calc(80/345*100%);
			text-align: right;
			padding-right: 10px;
		}
		&-link {
			display: block;
			text-decoration: none;
			color: inherit;
			& > img {
				display: block;
				max-width: 100%;
				height: auto;
			}
		}
	}
	&__del {
		display: block;
		& > img {
			display: block;
			max-width: 100%;
			height: auto;
			margin-left: auto;
		}
	}
	&__price {
		font-weight: 400;
		font-size: rem(10px);
		color: #000000;
		& > span {
			font-size: rem(17px);
			font-weight: 700;
		}
	}
	&__all {
		display: flex;
		justify-content: space-between;
		font-weight: 400;
		font-size: rem(14px);
		color: #000000;
		margin-bottom: 10px;
		span {
			font-weight: 700;
			color: #ff0000;
			font-size: rem(18px);
		}
	}
	&__quality {
		border-radius: 5px;
		border: 1px solid #a9a9a9;
		display: flex;
		justify-content: space-between;
		overflow: hidden;
		font-size: rem(17px);
		color: #000000;
		&-input {
			width: calc(100%/3);
			display: block;
			box-sizing: border-box;
			outline: none;
			border: none;
			text-align: center;
			line-height: rem(22px);
		}
		&-down,
		&-up {
			width: calc(100%/3);
			display: block;
			text-align: center;
			cursor: pointer;
			color: #000000;
			&:hover {
				color: #ff0000;
			}
		}
	}
	&__open {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
		z-index: 1;
	}
}

.basket__list::-webkit-scrollbar {
	width: 3px;
}

.basket__list::-webkit-scrollbar-track {
	background-color: #bbbbbb;
}

.basket__list::-webkit-scrollbar-thumb {
	background-color: #ff0000;
}

.breadcrumbs {
	background-color: rgba(255,255,255,.5);
	&__list {
		display: flex;
	}
	&__item {
		&:first-child {
			.breadcrumbs__link {
				padding-left: 12px;
				&:before {
					display: none;
				}
			}
		}
	}
	&__link {
		display: block;
		text-decoration: none;
		line-height: rem(30px);
		font-size: rem(14px);
		font-weight: 400;
		color: #000000;
		padding: 0 12px 0 24px;
		position: relative;
		&:before {
			content: '';
			display: block;
			height: 6px;
			width: 9px;
			background: url('/img/breadcrumbs-arrow.png') no-repeat;
			position: absolute;
			left: 0;
			top: 50%;
			margin-top: -3px;
		}
		&:hover {
			color: #ff0000;
		}
	}
}

.materials {
	margin-bottom: 17px;
    .title {
        padding: 30px 42px;
    }
	&__house {
		padding: 0 42px;
		background: #fff url('/img/house.jpg') no-repeat;
		min-height: 539px;
		position: relative;
		z-index: 1;
	}
	&__list {
		width: calc(220/1116*100%);
		&_points {
			.materials__item {
				width: 22px;
				height: 22px;
				border-radius: 50%;
				box-sizing: border-box;
				border: 1px solid #000000;
				min-height: auto;
				display: block;
				margin-bottom: 0;
				position: absolute;
				z-index: -2;
				&_active {
					box-shadow: 0 0 27px 12px #fff;
					background-color: #ff0000;
					border: none;
					z-index: auto;
					.submenu {
						display: block;
					}
				}
			}
			.materials__link {
				font-size: 0;
				padding: 0;
				width: 100%;
				height: 100%;
			}
		}
	}
	&__item {
		border: 1px solid #878787;
		border-radius: 30px;
		background-color: #fff;
		min-height: 42px;
		display: flex;
		align-items: center;
		margin-bottom: 18px;
		&.on {
			background-color: #ff0000;
			border-color: #ff0000;
			.materials__link {
				color: #ffffff;
			}
		}
		&_active {
			background-color: #ff0000;
			border-color: #ff0000;
			.materials__link {
				color: #ffffff;
			}
		}
		&_accessories {
			top: 30%;
			left: 27%;
		}
		&_isolation {
			top: 10%;
			left: 38%;
		}
		&_roof {
			top: 16%;
			left: 55%;
		}
		&_related {
			top: 32%;
			left: 52%;
		}
		&_facades {
			bottom: 9%;
			left: 26%;
		}
		&_ceramics {
			bottom: 8%;
			left: 50%;
		}
		&_belle {
			top: 58%;
			left: 62%;
		}
		&_landscape {
			top: 37%;
			left: 76%;
		}
	}
	&__link {
		display: block;
		color: #000000;
		text-transform: uppercase;
		text-decoration: none;
		font-weight: 700;
		font-size: rem(11px);
		padding: 6px 18px;
		width: 100%;
		box-sizing: border-box;
		cursor: pointer;
	}
}

.submenu {
	width: 237px;
	background-color: rgba(255,255,255,.9);
	border-radius: 5px;
	margin-top: -10px;
	margin-left: 10px;
	position: relative;
	z-index: -1;
	padding: 15px 17px;
	box-sizing: border-box;
	display: none;
	&__link {
		text-decoration: none;
		display: block;
		font-size: rem(14px);
		font-weight: 300;
		margin-bottom: 8px;
		color: rgba(0,0,0,1);
		&:hover {
			color: rgba(255,0,0,1);
		}
	}
}

.line {
	flex-grow: 1;
	border-bottom: 1px dashed #a1a1a1;
	margin-bottom: 5px;
}

.slider-new {
	margin-bottom: 17px;
    .title {
        padding-bottom: 25px;
    }
	.shadow {
		padding: 30px 45px;
	}
	&__link {
		font-weight: 600;
		font-size: rem(24px);
		text-transform: uppercase;
		text-decoration: none;
		color: #000000;
		line-height: rem(39px);
		margin-left: 65px;
		position: relative;
		&_active {
			color: #ff0000;
		}
		&:hover {
			color: #ff0000;
		}
		&:before {
			content: '';
			display: block;
			width: 1px;
			height: 100%;
			position: absolute;
			top: 0;
			left: -33px;
			background-color: #828282;
		}
		&:nth-of-type(1) {
			margin-left: 0;
			&:before {
				display: none;
			}
		}
	}
	&__slider {
		padding: 23px 30px 0;
		box-sizing: border-box;
		&_item {
			width: 100%;
			display: flex;
			padding: 0;
			.slider-new__slide {
				margin-left: 23px;
				&:first-child {
					margin-left: 0;
				}
			}
		}
		&_category {
			padding: 0;
			display: flex;
			flex-wrap: wrap;
			.slider-new__slide {
				width: 30%;
				margin: 0 1.66% 23px 1.66%;
				&:nth-last-child(-n+3) {
					margin-bottom: 0;
				}
			}
			&-table {
				.slider-new__slide {
					width: 100%;
					position: relative;
					&:nth-last-child(-n+3) {
						margin-bottom: 23px;
					}
					&:last-child {
						margin-bottom: 0;
					}
					&-header {
						display: flex;
						justify-content: space-between;
						align-items: flex-start;
						border-bottom: 1px solid #a9a9a9;
						border-bottom-right-radius: 5px;
						border-bottom-left-radius: 5px;
					}
					&-title {
						width: 43%;
					}
					&-footer {
						position: absolute;
						right: 0;
						bottom: 0;
					}
					&-img {
						img {
							max-width: 187px;
						}
					}
				}
				.label {
					width: 7.7%;
					top: 9%;
				}
			}
			&-string {
				.slider-new__slide {
					width: 100%;
					position: relative;
					&:nth-last-child(-n+3) {
						margin-bottom: 23px;
					}
					&:last-child {
						margin-bottom: 0;
					}
					&-header {
						display: flex;
						justify-content: flex-start;
						align-items: center;
						border-bottom: 1px solid #a9a9a9;
						border-bottom-right-radius: 5px;
						border-bottom-left-radius: 5px;
						padding: 8px 0 8px 10px;
					}
					&-title {
						width: 30%;
						min-height: auto;
						margin-right: 35px;
					}
					&-footer {
						position: absolute;
						right: 0;
						bottom: 0;
						width: 25%;
					}
					&-img {
						margin-right: 20px;
						img {
							min-height: 84px;
							margin-bottom: 0;
							max-width: 85px;
						}
					}
					&-prices {
						min-height: auto;
					}
					&-sale,
					&-wholesale {
						font-size: rem(13px);
					}
					&-price {
						span {
							font-size: rem(24px);
						}
					}
					&-btn {
						padding: 0 10px;
					}
				}
				.label {
					width: 7.7%;
					top: 8px;
					&__sale {
						font-size: rem(17px);
						line-height: rem(25px);
					}
				}
			}
		}
	}
	&__slide {
		width: calc(232/263*100%);
		margin: 0 auto;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		height: 100%;
		&:hover {
			.slider-new__slide-header {
				border-color: #ff0000;
			}
		}
		&-header {
			border-top-left-radius: 5px;
			border-top-right-radius: 5px;
			padding: 20px 20px 8px 20px;
			border-top: 1px solid #a9a9a9;
			border-left: 1px solid #a9a9a9;
			border-right: 1px solid #a9a9a9;
			position: relative;
			flex-grow: 1;
		}
		&-footer {
			display: flex;
			justify-content: space-between;
		}
		&-btn {
			display: flex;
			align-items: center;
			text-decoration: none;
			font-weight: 400;
			font-size: rem(16px);
			width: 50%;
			flex-basis: 50%;
			box-sizing: border-box;
			text-align: center;
			padding: 3px 15px;
			cursor: pointer;
			justify-content: center;
			&:hover {
				color: #ffffff;
			}
			&_set {
				border: 1px solid #a9a9a9;
				color: #8b8b8b;
				border-bottom-left-radius: 5px;
				background-color: #ffffff;
				&:hover {
					background-color: #8b8b8b;
					border-color: #8b8b8b;
				}
			}
			&_buy {
				color: #ffffff;
				background-color: #ff0000;
				border-bottom: 1px solid #a9a9a9;
				border-right: 1px solid #a9a9a9;
				border-top: 1px solid #a9a9a9;
				border-bottom-right-radius: 5px;
				&:hover {
					background-color: #3c3c3c;
					border-color: #3c3c3c;
				}
			}
		}
		&-img {
			img {
				width: 100%;
				height: auto;
				display: block;
				margin: 0 auto 8px;
				min-height: 184px;
				max-height: 184px;
			}
		}
		&-title {
			min-height: 3.9em;
			display: -webkit-box;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;
			overflow: hidden;
			text-overflow: ellipsis;
			max-height: 3.9em;
			line-height: 1.3;
			margin-bottom: 10px;
			word-break: break-word;
		}
		&-prices {
			min-height: 80px;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
		}
		&-sale {
			font-size: rem(18px);
			color: #ff0000;
			font-weight: 400;
			text-decoration: line-through;
			display: none;
		}
		&-price {
			font-size: rem(18px);
			color: #000000;
			line-height: rem(30px);
			span {
				font-size: rem(30px);
				font-weight: 700;
			}
		}
		&_sale {
			.slider-new__slide-sale {
				display: block;
			}
			.label {
				display: block;
			}
		}
	}
}

.label {
	position: absolute;
	left: 0;
	top: 5.3%;
	width: 25%;
	&__sale {
		font-weight: 600;
		font-size: rem(18px);
		color: #ffffff;
		background-color: #ff0000;
		text-align: center;
		width: 100%;
		line-height: rem(30px);
	}
	&__hit {
		@extend .label__sale;
		background-color: #ffde4a;
		color: #000000;
	}
	&__new {
		@extend .label__sale;
		background-color: #6fd88e;
	}
	&_item {
		top: 16px;
		width: 20%;
	}
}

.slider-partners {
	margin-bottom: 17px;
	.shadow {
		padding: 30px 45px 45px 45px;
	}
	&__title {
		font-size: rem(24px);
		font-weight: 600;
		text-transform: uppercase;
		margin-bottom: 40px;
	}
	&__slider {
		padding: 0 60px;
		box-sizing: border-box;
	}
	&__link {
		display: block;
		margin: 0 10px;
	}
	&__img {
		display: block;
		max-width: 100%;
		height: auto;
		margin: 0 auto;
	}
}

.banner {
	margin-bottom: 17px;
	&__img {
		display: block;
		width: 100%;
		height: auto;
	}
}

.navigation {
	margin-bottom: 17px;
	.shadow {
		padding: 30px 60px 0 60px;
		display: flex;
		flex-wrap: wrap;
	}
	&__col {
		width: 23%;
		margin: 0 1% 30px 1%;
		&-title {
			font-size: rem(16px);
			font-weight: 700;
			text-transform: uppercase;
			margin-bottom: 5px;
			display: block;
			text-decoration: none;
			color: #000000;
			&:hover {
				color: #ff0000;
			}
		}
		&-link {
			text-decoration: none;
			font-size: rem(14px);
			font-weight: 400;
			color: #000000;
			&:hover {
				color: #ff0000;
			}
		}
	}
}

.footer {
	background-color: rgba(255,255,255,.5);
	padding: 15px 0;
	flex: 0 0 auto;
	&__wrapper {
		display: flex;
		justify-content: space-between;
	}
}

.copyright {
	width: calc(500/1200*100%);
	font-size: rem(14px);
	font-weight: 400;
	margin-left: calc(60/1200*100%);
	& > a {
		color: inherit;
		text-decoration: inherit;
	}
}

.agatech {
	width: calc(300/1200*100%);
	display: flex;
	align-items: flex-end;
	text-align: right;
	&__text {
		font-size: rem(16px);
		margin-right: 10px;
		margin-bottom: -4px;
		color: #434343;
		font-weight: 600;
	}
	&__link {
		display: block;
	}
	&__img {
		display: block;
		max-width: 100%;
		height: auto;
	}
}

.docs {
	margin-bottom: 17px;
	.title {
		padding: 30px 42px;
	}
	&__table {
		border-collapse: collapse;
		width: 100%;
		&-wrapper {
			padding: 0 42px 55px 42px;
		}
	}
	&__tr {
		&:hover {
			.docs__link {
				color: #ff0000;
			}
            .download {
                fill: #ff0000;
            }
		}
	}
	&__td {
		border-bottom: 1px solid #b1b1b1;
		border-right: 1px solid #b1b1b1;
		&_title {
			width: 95%;
		}
		&_download {
			width: 5%;
			border-right: none;
		}
	}
	&__link {
		text-decoration: none;
		color: #000000;
		font-size: rem(18px);
		font-weight: 600;
		display: block;
		padding: 8px 35px;
	}
	&__btn {
		display: block;
		& > img {
			display: block;
			max-width: 100%;
			height: auto;
			margin: 0 auto;
		}
	}
}

.download {
    fill: #989898;
    width: 40%;
    display: block;
    margin: 0 auto;
}

.article {
    margin-bottom: 17px;
    .shadow {
		padding: 30px 45px;
	}
    &__title {
        font-size: rem(24px);
        font-weight: 600;
        text-transform: uppercase;
    }
    &__text {
        margin-bottom: 25px;
    }
}

.category {
    margin-bottom: 17px;
    .shadow {
		padding: 30px 45px 0 45px;
	}
    &__title {
        font-size: rem(24px);
        font-weight: 600;
        text-transform: uppercase;
    }
    &__list {
        padding: 0 calc(40/1110*100%);
        display: flex;
        flex-wrap: wrap;
    }
    &__item {
		position: relative;
        width: calc(25% - 0.9009% * 2);
        box-sizing: border-box;
        border: 7px solid #cccccc;
        margin: 0 calc(10/1110*100%) 30px calc(10/1110*100%);
        border-radius: 5px;
        background: linear-gradient(to bottom, #b6b6b6 0%, #3f3f3f 100%);
        &:hover {
            background: #ffffff;
            .category__item-title {
                color: #000000;
            }
            .category__item-icon_roof {
                background: #ff0000 url('/img/roof-hover.png') center no-repeat;
            }
            .category__item-icon_facades {
                background: #ff0000 url('/img/facades-hover.png') center no-repeat;
            }
            .category__item-icon_accessories {
                background: #ff0000 url('/img/accessories-hover.png') center no-repeat;
            }
            .category__item-icon_ceramics {
                background: #ff0000 url('/img/ceramics-hover.png') center no-repeat;
            }
            .category__item-icon_landscape {
                background: #ff0000 url('/img/landscape-hover.png') center no-repeat;
            }
            .category__item-icon_another {
                background: #ff0000 url('/img/another-hover.png') center no-repeat;
            }
            .category__item-icon_insulation {
                background: #ff0000 url('/img/insulation-hover.png') center no-repeat;
            }
            .category__item-icon_belledecor {
                background: #ff0000 url('/img/belledecor-hover.png') center no-repeat;
            }
        }
        &-title {
            text-align: center;
            padding: 0 calc(23/225*100%) 5px calc(23/225*100%);
            font-weight: 700;
            font-size: rem(18px);
            text-transform: uppercase;
            color: #ffffff;
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 80px;
        }
        &-icon {
            font-size: 0;
            max-width: 112px;
            min-height: 112px;
            margin: 15px auto 5px auto;
            border-radius: 50%;
            box-shadow: inset 0 0 10px 0 rgba(0,0,0,.98), 0 0 1px 0 rgba(255,255,255,1);
            &_roof {
                background: #ffffff url('/img/roof.png') center no-repeat;
            }
            &_facades {
                background: #ffffff url('/img/facades.png') center no-repeat;
            }
            &_accessories {
                background: #ffffff url('/img/accessories.png') center no-repeat;
            }
            &_ceramics {
                background: #ffffff url('/img/ceramics.png') center no-repeat;
            }
            &_landscape {
                background: #ffffff url('/img/landscape.png') center no-repeat;
            }
            &_another {
                background: #ffffff url('/img/another.png') center no-repeat;
            }
            &_insulation {
                background: #ffffff url('/img/insulation.png') center no-repeat;
            }
            &_belledecor {
                background: #ffffff url('/img/belledecor.png') center no-repeat;
            }
        }
		&-link {
			position: absolute;
			font-size: 0;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			margin: auto;
		}
    }
}

.news {
	margin-bottom: 17px;
	.title {
		width: calc(800/1070*100%);
	}
	.shadow {
		padding: 25px 90px 20px 40px;
	}
	&__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 30px;
	}
	&__select {
		box-sizing: border-box;
		outline: none;
		width: calc(123/254*100%);
		display: block;
		border-radius: 5px;
		padding: 1px 0;
		font-size: rem(18px);
	}
	&__filter {
		width: calc(254/1070*100%);
		display: flex;
		justify-content: space-between;
	}
	&__item {
		border: 1px solid #a9a9a9;
		border-radius: 5px;
		margin-left: 5%;
		margin-top: 11px;
		padding: 10px 15px;
		display: flex;
		justify-content: space-between;
		&:first-child {
			margin-top: 0;
		}
	}
	&__left {
		width: calc(230/985*100%);
	}
	&__right {
		width: calc(730/985*100%);
	}
	&__img {
		display: block;
		width: 100%;
		height: auto;
	}
	&__title {
		font-size: rem(24px);
		text-transform: uppercase;
		font-weight: 600;
		margin-bottom: 10px;
	}
	&__descr {
		margin-bottom: 16px;
	}
	&__footer {
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
	}
	&__date {
		color: #ff0000;
		font-size: rem(16px);
		display: block;
	}
	&__more {
		text-transform: uppercase;
		display: block;
		text-decoration: none;
		line-height: rem(34px);
		color: #373737;
		padding: 0 45px;
		background: linear-gradient(to bottom, #dedede 0%,#ababab 100%);
		border-radius: 5px;
		box-shadow: 0 2px 7px 0 rgba(0,0,0,.17);
		&:hover {
			background: #ff0000;
			color: #ffffff;
		}
	}
	&__inner {
		@include clearfix();
		width: calc(1130/1200*100%);
		margin-left: auto;
	}
	&__bottom {
		display: flex;
		justify-content: space-between;
	}
	&__prev,
	&__next {
		display: block;
		text-decoration: none;
		font-size: rem(20px);
		color: #000000;
		&:hover {
			color: #ff0000;
		}
	}
}

.pagination {
	margin-top: 25px;
	margin-left: auto;
	display: flex;
	justify-content: flex-end;
	&__list {
		display: flex;
	}
	&__link {
		line-height: rem(21px);
		border: 2px solid transparent;
		text-decoration: none;
		color: #000000;
		font-size: rem(18px);
		padding: 0 7px;
		&_active {
			border-color: #c0c0c0;
		}
		&:hover {
			border: 2px solid #c0c0c0;
		}
	}
	&__next {
		@extend .pagination__link;
		&:hover {
			border-color: transparent;
			color: #ff0000;
		}
	}
}

.error {
	margin-bottom: 17px;
	&__wrapper {
		background-color: #ffffff;
		box-shadow: 0 8px 16px 0 rgba(0,0,0,.32);
		padding: 85px 45px;
	}
	&__title {
		text-transform: uppercase;
		font-weight: 600;
		text-align: center;
		font-size: rem(68px);
		border-bottom: 1px solid #b1b1b1;
		padding-bottom: 40px;
	}
	&__text {
		font-weight: 600;
		text-align: center;
		font-size: rem(34px);
		color: #ff0000;
	}
}

.item {
	margin-bottom: 17px;
	.title {
		margin-bottom: 30px;
	}
	.shadow {
		padding: 30px 40px;
	}
	&__inner {
		margin: 0 calc(50/1120*100%);
		display: flex;
		justify-content: space-between;
		align-items: stretch;
	}
	&__aside {
		width: calc(235/1020*100%);
		box-sizing: border-box;
		&-block {
			border: 1px solid #aeaeae;
			border-radius: 5px;
			padding: 28px 12px;
			margin-bottom: 18px;
		}
		&-title {
			font-size: rem(16px);
			font-weight: 700;
			text-transform: uppercase;
			padding: 0 18px 15px 18px;
			border-bottom: 1px solid #ff0000;
			margin-bottom: 20px;
			background-image: url('/img/arrow-down.png');
			background-repeat: no-repeat;
			background-position: top 5px right 18px;
			&_no-bd {
				border: none;
			}
		}
		&-list {
			padding: 0 18px;
			&_type-item {
				padding-bottom: 13px;
			}
		}
		&-sublist {
			padding-left: 20px;
		}
		&-link {
			font-size: rem(14px);
			text-decoration: none;
			color: #000000;
			&:hover {
				color: #ff0000;
			}
			&_active {
				color: #ff0000;
			}
		}
		&-btn {
			display: block;
			width: 173px;
			background: linear-gradient(to bottom, #dedede 0%,#ababab 100%);
			border: none;
			outline: none;
			box-shadow: 0 2px 7px 0 rgba(0,0,0,.17);
			line-height: rem(36px);
			font-weight: 700;
			font-size: rem(16px);
			text-transform: uppercase;
			border-radius: 5px;
			cursor: pointer;
			margin: 0 auto;
			&:hover {
				background: #ff0000;
				color: #ffffff;
			}
		}
	}
	&__right {
		width: calc(745/1020*100%);
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		&_category {
			width: 75.73922%;
			justify-content: flex-start;
		}
	}
	&__slider {
		position: relative;
		width: calc(390/745*100%);
		border: 1px solid #c5c5c5;
		box-sizing: border-box;
		border-radius: 5px;
		padding: 16px 18px;
		.owl-prev,
		.owl-next {
			width: 44px;
			height: 85px;
			margin-top: -43px;
			background-position: center;
		}
		.owl-prev {
			left: 0;
			background-color: rgba(255,255,255,.5);
			border-bottom-right-radius: 5px;
			border-top-right-radius: 5px;
		}
		.owl-next {
			right: 0;
			background-color: rgba(255,255,255,.5);
			border-bottom-left-radius: 5px;
			border-top-left-radius: 5px;
		}
	}
	&__top {
		display: flex;
		justify-content: space-between;
		align-items: stretch;
		margin-bottom: 20px;
	}
	&__middle {
		&-inner {
			border: 1px solid #c5c5c5;
			border-radius: 5px;
			padding: 15px 20px 20px 20px;
			margin-bottom: 30px;
		}
		&-title {
			font-size: 1.25rem;
			font-weight: 700;
			margin-bottom: 10px;
		}
		&-text {
			margin: 0;
		}
	}
	&__title {
		font-size: 1.5rem;
		font-weight: 600;
		text-transform: uppercase;
		margin-bottom: 20px;
	}
	&__slide {
		img {
			display: block;
			width: 100%;
			height: auto;
		}
	}
	&__info {
		width: calc(310/745*100%);
		&-title {
			font-size: rem(20px);
			font-weight: 700;
			margin-bottom: 50px;
			word-break: break-word;
		}
		&-sale {
			border-top: 1px solid #ff0000;
			margin-bottom: 5px;
			&-text {
				display: inline-block;
				vertical-align: top;
				background-color: #ff0000;
				font-size: rem(18px);
				font-weight: 400;
				color: #ffffff;
				line-height: rem(29px);
				padding: 0 14px;
			}
		}
		&-price {
			font-size: rem(18px);
			span {
				font-size: rem(30px);
				font-weight: 700;
			}
			&_sale {
				font-size: rem(18px);
				color: red;
				font-weight: 400;
				text-decoration: line-through;
			}
		}
		&-quality {
			font-size: rem(20px);
			display: flex;
			align-items: center;
			.basket__quality {
				width: 30%;
				box-sizing: border-box;
				margin-left: 10px;
				font-size: rem(22px);
				font-weight: 700;
				&-input {
					line-height: rem(28px);
				}
			}
		}
		&-wholesale {
			font-size: rem(18px);
			margin-bottom: 7px;
			span {
				font-weight: 700;
			}
		}
		&-total {
			font-size: rem(30px);
			span {
				font-weight: 700;
				color: #ff0000;
			}
		}
		&-text {
			font-size: rem(14px);
			color: #424242;
		}
		&-btn {
			display: block;
			line-height: rem(44px);
			border-radius: 5px;
			text-decoration: none;
			text-align: center;
			width: 90%;
			font-size: rem(16px);
			box-sizing: border-box;
			&_fast-buy {
				background-color: #ff0000;
				color: #ffffff;
				margin-bottom: 8px;
				&:hover {
					background-color: #6e6e6e;
				}
			}
			&_basket {
				border: 1px solid #acacac;
				line-height: rem(42px);
				color: #000000;
				&:hover {
					border-color: #ff0000;
					color: #ff0000;
				}
			}
		}
	}
	&__bottom {
		&-title {
			font-size: rem(24px);
			font-weight: 600;
			text-transform: uppercase;
			margin-bottom: 20px;
		}
	}
}

.basket-page {
	margin-bottom: 17px;
	.title {
		margin-bottom: 20px;
	}
	.shadow {
		padding: 30px 40px 70px 40px;
	}
	&__top {
		margin-bottom: 140px;
	}
	&__table {
		border-collapse: collapse;
		width: 100%;
		&-wrapper {
			border: 1px solid #a9a9a9;
			border-radius: 5px;
			padding: 8px 5px;
			margin-bottom: 18px;
		}
	}
	&__th {
		text-align: center;
		color: #515151;
		font-size: rem(18px);
		font-weight: 400;
		border-bottom: 1px solid #a9a9a9;
		border-left: 1px solid #a9a9a9;
		line-height: rem(38px);
		&:first-child {
			border-left: none;
		}
	}
	&__td {
		text-align: center;
		color: #000000;
		border-bottom: 1px solid #a9a9a9;
		border-left: 1px solid #a9a9a9;
		&:first-child {
			border-left: none;
		}
		&_title {
			text-align: left;
			padding: 0 15px;
			font-size: rem(18px);
		}
		&_img {
			width: 122px;
			img {
				display: block;
				max-width: 100%;
				height: auto;
				margin: 8px auto;
			}
		}
		&_price,
		&_total {
			span {
				font-size: rem(30px);
				font-weight: 700;
			}
		}
		&_alltotal {
			border-bottom: none;
			font-size: rem(18px);
			text-align: right;
			span {
				font-size: rem(30px);
				font-weight: 700;
				color: #ff0000;
			}
		}
		&_btn {
			a {
				font-weight: 400;
				font-size: rem(21px);
				color: #ff0000;
				text-transform: uppercase;
				text-decoration: none;
				display: block;
				&:hover {
					color: #000000;
				}
			}
		}
		&_quality {
			width: 12%;
		}
		.basket__quality {
			width: 90%;
			margin: 0 auto;
			font-size: rem(24px);
			font-weight: 700;
		}
	}
	&__art {
		font-size: rem(18px);
		span {
			color: #ff0000;
		}
	}
	&__footer {
		display: flex;
		justify-content: flex-end;
	}
	&__btn {
		display: block;
		text-decoration: none;
		text-transform: uppercase;
		line-height: rem(42px);
		margin-left: 15px;
		border-radius: 5px;
		padding: 0 12px;
		&:first-child {
			margin-left: 0;
		}
		&_clean {
			border: 1px solid #a9a9a9;
			line-height: rem(40px);
			background-color: #ffffff;
			color: #b0b0b0;
			&:hover {
				color: #ff0000;
				border-color: #ff0000;
			}
		}
		&_fastbuy {
			color: #ffffff;
			background-color: #ff0000;
			&:hover {
				background-color: #6e6e6e;
			}
		}
		&_contents {
			background: linear-gradient(to bottom, #dedede 0%,#ababab 100%);
			color: #373737;
			&:hover {
				background: #ff0000;
				color: #ffffff;
			}
		}
	}
	&__bottom {
		&-title {
			font-size: rem(24px);
			font-weight: 600;
			text-transform: uppercase;
			padding: 0 45px;
		}
	}
	&__link {
		display: block;
		text-decoration: none;
		color: inherit;
	}
}

.fast-buy {
	margin-bottom: 30px;
	.title {
		margin-bottom: 20px;
	}
	.shadow {
		padding: 30px 40px 50px 40px;
	}
	&__form {
		width: calc(560/1120*100%);
		margin: 0 auto;
	}
	&__field {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 19px;
	}
	&__label {
		display: block;
		width: calc(190/560*100%);
		font-size: rem(18px);
		span {
			color: #ff0000;
		}
	}
	&__input {
		box-sizing: border-box;
		outline: none;
		border-radius: 5px;
		border: 1px solid #898989;
		display: block;
		width: calc(370/560*100%);
		font-weight: 600;
		font-size: rem(18px);
		padding: 3px 15px;
	}
	&__tooltip {
		text-align: center;
		font-size: rem(12px);
		color: #818181;
		margin-bottom: 23px;
	}
	&__text {
		color: #000000;
		text-align: center;
		margin-bottom: 23px;
		font-weight: 600;
	}
	&__btn {
		display: block;
		margin: 0 auto;
		text-transform: uppercase;
		background-color: #ff0000;
		color: #ffffff;
		border: none;
		outline: none;
		cursor: pointer;
		line-height: rem(30px);
		padding: 0 58px;
		border-radius: 5px;
		box-shadow: 0 2px 7px 0 rgba(0,0,0,.17);
		&:hover {
			background-color: #6e6e6e;
		}
	}
}

.category {
	&__title {
		font-size: rem(30px);
		font-weight: 600;
		margin-bottom: 15px;
	}
}

.grid {
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	margin: 0 62px 15px 50px;
	&__title {
		font-size: rem(14px);
		margin-left: 20px;
	}
	&__list {
		display: flex;
		margin-left: 20px;
		&_layer {
			.grid__link {
				display: block;
				text-decoration: none;
				width: 27px;
				height: 27px;
				box-sizing: border-box;
				border: 1px solid #7c7c7c;
				font-size: 0;
				&:before {
					display: none;
				}
				&_table {
					background: #fff url('/img/icon-table.png') center no-repeat;
				}
				&_block {
					background: #fff url('/img/icon-block.png') center no-repeat;
				}
				&_string {
					background: #fff url('/img/icon-string.png') center no-repeat;
				}
				&_active {
					&.grid__link {
						&_string {
							background: #fff url('/img/icon-string-hover.png') center no-repeat;
						}
						&_table {
							background: #fff url('/img/icon-table-active.png') center no-repeat;
						}
						&_block {
							background: #fff url('/img/icon-block-active.png') center no-repeat;
						}
					}
				}
			}
			.grid__item {
				margin-left: 8px;
				&:first-child {
					margin-left: 0;
				}
			}
		}
	}
	&__item {
		&:first-child {
			.grid__link {
				padding-left: 0;
				&:before {
					display: none;
				}
			}
		}
	}
	&__link {
		font-size: rem(14px);
		font-weight: 400;
		text-decoration: none;
		position: relative;
		color: #000000;
		padding-left: 13px;
		cursor: pointer;
		&_active {
			text-decoration: underline;
			color: #ff0000;
		}
		&:before {
			content: '';
			display: block;
			width: 13px;
			height: 100%;
			position: absolute;
			background-image: url('/img/border.png');
			background-repeat: no-repeat;
			background-position: center bottom 1px;
		}
	}
}

.order {
    margin-bottom: 17px;
    .title {
        margin-bottom: 15px;
		padding: 0 18px;
    }
    .shadow {
		padding: 30px 28px 70px 24px;
	}
    &__inner {
        //margin-left: calc(45/1120*100%);
        display: flex;
        justify-content: space-between;
    }
    &__left {
        width: calc(880/1148*100%);
    }
    &__right {
        width: calc(246/1148*100%);
    }
	&__basket {
		padding: 10px 11px;
		position: static;
		width: auto;
		border: 1px solid #898989;
		display: block;
		.basket__item-img {
			width: calc(55/222*100%);
			margin-right: 10px;
		}
		.basket__item {
			justify-content: flex-start;
			position: relative;
		}
		.basket__item-title {
			width: auto;
			& > a {
				line-height: 1.2;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				overflow: hidden;
				text-overflow: ellipsis;
				font-size: rem(12px);
				margin-bottom: 3px;
				word-break: break-word;
				min-height: 2.4em;
				max-height: 2.4em;
			}
		}
		.basket__item-link {
			& > img {
				width: 47px;
				max-width: none;
			}
		}
		.basket__price {
			width: 80px;
			min-height: 23px;
		}
		.basket__item-right {
			position: absolute;
			width: 72px;
			bottom: 6px;
			right: 0;
		}
		.basket__all {
			justify-content: center;
		}
		.basket__list {
			margin-bottom: 10px;
			max-height: 298px;
		}
		.basket__link {
			font-size: rem(14px);
		}
	}
}

.tabs {
    &__control {
        &-list {
            display: flex;
            background: linear-gradient(to bottom, #dedede 0%,#ababab 100%);
            border-radius: 5px;
            overflow: hidden;
            margin: 0 24px 30px;
        }
        &-item {
            flex-grow: 1;
            border-left: 1px solid #717171;
            &:first-child {
                border-left: none;
            }
            &_active {
                background-color: #3e3e3e;
                .tabs__control-link {
                    color: #ffffff;
                    position: relative;
                    &:before {
                        content: '';
                        width: 0;
                        height: 0;
                        position: absolute;
                        border-style: solid;
                        border-width: 6.5px 0 6.5px 7px;
                        border-color: transparent transparent transparent #ff0000;
                        right: 7px;
                        top: 50%;
                        margin-top: -6px;
                    }
                }
            }
        }
        &-link {
            display: block;
            text-decoration: none;
            color: #717171;
            line-height: rem(29px);
            padding: 0 38px 0 9px;
            font-size: rem(14px);
			cursor: default;
        }
    }
	&__form {
		display: flex;
		justify-content: space-between;
		&-left,
		&-right {
			width: calc(420/880*100%);
			box-sizing: border-box;
			position: relative;
		}
		&-bottom {
			text-align: right;
			margin-top: 65px;
			&-text {
				font-size: rem(12px);
				color: #818181;
				margin-bottom: 12px;
				& > span {
					color: #ff0000;
				}
			}
			&-next,
			&-prev {
				display: inline-block;
				vertical-align: top;
				border: none;
				background-color: #ff0000;
				outline: none;
				font-size: rem(14px);
				color: #ffffff;
				text-transform: uppercase;
				line-height: rem(30px);
				padding: 0 50px;
				border-radius: 4px;
				box-shadow: 0 2px 7px 0 rgba(0,0,0,.17);
				cursor: pointer;
			}
			&-prev {
				position: absolute;
				bottom: 0;
				left: 0;
			}
		}
		&-title {
			font-weight: 600;
			color: #000000;
			text-transform: uppercase;
			font-size: rem(16px);
		}
		&-subtitle {
			font-weight: 600;
			color: #000000;
			font-size: rem(16px);
			margin-top: 16px;
		}
	}
    &__item {
        display: none;
		position: relative;
        &_active {
            display: block;
        }
    }
    &__field {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 19px;
        &:first-child {
            margin-top: 0;
        }
		&_face {
			width: auto;
			display: flex;
			justify-content: center;
			margin-bottom: 30px;
			.tabs {
				&__field {
					display: flex;
					margin: 0;
				}
				&__input {
					width: auto;
					position: absolute;
					left: -9999px;
					&:checked {
						& + .tabs__label {
							&:before {
								background-image: url('/img/dot-active.png');
								background-repeat: no-repeat;
								background-position: center;
								border-color: #ff0000;
							}
						}
					}
				}
				&__label {
					width: auto;
					font-size: rem(16px);
					padding-right: 60px;
					position: relative;
					&:before {
						content: '';
						display: block;
						position: absolute;
						top: 50%;
						margin-top: -14px;
						width: 27px;
						height: 27px;
						box-sizing: border-box;
						right: 0;
						border-radius: 50%;
						border: 1px solid #adadad;
						background-image: url('/img/dot.png');
						background-repeat: no-repeat;
						background-position: center;
					}
				}
			}
		}
		&_file {
			.tabs {
				&__input {
					width: auto;
					position: absolute;
					left: -9999px;
					&:checked {
						& + .tabs__label {
							&:before {
								background-image: url('/img/dot-active.png');
								background-repeat: no-repeat;
								background-position: center;
								border-color: #ff0000;
							}
						}
					}
				}
				&__label {
					width: calc(245/420*100%);
					box-sizing: border-box;
					padding-right: 60px;
					position: relative;
					&:before {
						content: '';
						display: block;
						position: absolute;
						top: 50%;
						margin-top: -14px;
						width: 27px;
						height: 27px;
						box-sizing: border-box;
						right: 0;
						border-radius: 50%;
						border: 1px solid #adadad;
						background-image: url('/img/dot.png');
						background-repeat: no-repeat;
						background-position: center;
					}
				}
			}
		}
		&_delivery {
			flex-wrap: wrap;
			margin-top: 25px;
			.tabs {
				&__input {
					width: auto;
					position: absolute;
					left: -9999px;
					&:checked {
						& + .tabs__label {
							&:before {
								background-image: url('/img/dot-active.png');
								background-repeat: no-repeat;
								background-position: center;
								border-color: #ff0000;
							}
						}
						& ~ .tabs__popup {
							display: block;
						}
					}
				}
				&__label {
					width: 77%;
					box-sizing: border-box;
					padding-right: 60px;
					position: relative;
					font-weight: 600;
					&:before {
						content: '';
						display: block;
						position: absolute;
						top: 50%;
						margin-top: -14px;
						width: 27px;
						height: 27px;
						box-sizing: border-box;
						right: 0;
						border-radius: 50%;
						border: 1px solid #adadad;
						background-image: url('/img/dot.png');
						background-repeat: no-repeat;
						background-position: center;
					}
				}
				&__select {
					width: 77%;
					margin-top: 20px;
					margin-bottom: 5px;
					box-sizing: border-box;
					border: 1px solid #898989;
					border-radius: 4px;
					outline: none;
					line-height: rem(29px);
					height: 29px;
					font-size: rem(16px);
					padding: 0 16px;
					color: #8a8a8a;
					appearance: none;
					background-image: url('/img/arrow-select.png');
					background-repeat: no-repeat;
					background-position: right 9px center;
				}
				&__popup {
					left: 0;
					width: 77%;
					top: 46%;
				}
			}
		}
		&_payment {
			position: relative;
			margin-top: 40px;
			.tabs {
				&__input {
					position: absolute;
					left: -9999px;
					&:checked {
						& + .tabs__label {
							&:before {
								background-image: url('/img/dot-active.png');
								background-repeat: no-repeat;
								background-position: center;
								border-color: #ff0000;
							}
						}
						& ~ .tabs__popup {
							display: block;
						}
					}
				}
				&__label {
					width: 34%;
					box-sizing: border-box;
					padding-right: 60px;
					position: relative;
					font-weight: 600;
					&:before {
						content: '';
						display: block;
						position: absolute;
						top: 50%;
						margin-top: -14px;
						width: 27px;
						height: 27px;
						box-sizing: border-box;
						right: 0;
						border-radius: 50%;
						border: 1px solid #adadad;
						background-image: url('/img/dot.png');
						background-repeat: no-repeat;
						background-position: center;
					}
				}
			}
		}
		&_address {
			margin-top: 12px;
			.tabs {
				&__label {
					font-weight: 600;
				}
			}
		}
		&_index {
			margin-top: 15px;
		}
    }
    &__label {
        display: block;
        font-size: rem(16px);
        width: 30%;
		line-height: rem(15px);
		cursor: pointer;
        span {
            color: #ff0000;
			display: none;
        }
    }
    &__input,
    &__textarea {
        box-sizing: border-box;
        display: block;
        border-radius: 5px;
        border: 1px solid #898989;
        outline: none;
        font-weight: 600;
        padding: 3px 15px;
        width: 70%;
        resize: none;
    }
	&__input {
		&_file {
			position: absolute;
			opacity: 1;
			top: 0;
		}
	}
    &__tooltip {
        margin-top: 50px;
        margin-bottom: 20px;
        font-size: rem(12px);
        color: #818181;
        text-align: center;
    }
    &__btn {
        display: block;
        background: linear-gradient(to bottom, #dedede 0%,#ababab 100%);
        border: none;
        outline: none;
        cursor: pointer;
        line-height: rem(30px);
        padding: 0 58px;
        color: #000000;
        border-radius: 5px;
        margin-left: auto;
        text-transform: uppercase;
		&:hover {
			background: #ff0000;
			color: #ffffff;
		}
    }
	&__data {
		margin-top: 25px;
		&:first-child {
			margin-top: 0;
		}
		&-title {
			font-weight: 700;
			font-size: rem(18px);
			margin-bottom: 15px;
		}
	}
	&__table {
		width: 100%;
	}
	&__td {
		font-weight: 700;
		&:first-child {
			font-weight: 400;
		}
	}
	&__total {
		font-weight: 700;
		margin-bottom: 10px;
		& > span {
			color: #ff0000;
		}
	}
	&__popup {
		position: absolute;
		right: 0;
		top: 0;
		width: 64%;
		font-size: 0.75rem;
		line-height: 1;
		display: none;
	}
	&_faq {
		width: calc(871/1140*100%);
		display: flex;
		justify-content: space-between;
		.tabs {
			&__control {
				&-wrapper {
					width: calc(195/871*100%);
				}
				&-item {
					border: 1px solid #898989;
					border-radius: 20px;
					margin-top: 6px;
					overflow: hidden;
					&:first-child {
						margin-top: 0;
					}
					&_active {
						border-color: #ff0000;
						.tabs__control-link {
							color: #ff0000;
						}
					}
					&:hover {
						border-color: #ff0000;
					}
				}
				&-link {
					display: block;
					text-decoration: none;
					color: #000000;
					line-height: rem(28px);
					text-align: center;
					background-color: #ffffff;
					padding: 0;
					&:before {
						display: none;
					}
					&:hover {
						color: #ff0000;
					}
				}
			}
			&__item {
				width: 100%;
			}
			&__list {
				&-wrapper {
					width: calc(628/871*100%);
				}
			}
			&__title {
				font-size: rem(18px);
				color: #000000;
				font-weight: 400;
				border-bottom: 1px solid #000000;
				display: inline-block;
				vertical-align: top;
				margin: 0 7px 11px 7px;
			}
		}
	}
}

.accordeon {
	&__trigger {
		display: block;
		text-decoration: none;
		color: #000000;
		background: url('/img/trigger-close.png') no-repeat left 20px center, linear-gradient(to bottom, #dedede 0%, #ababab 100%);
		border-radius: 5px;
		line-height: rem(29px);
		padding: 0 57px;
		font-size: rem(18px);
		font-weight: 700;
		box-shadow: 0 2px 7px 0 rgba(0,0,0,.17);
		margin-bottom: 8px;
	}
	&__item {
		&_active {
			.accordeon__trigger {
				background: url('/img/trigger-open.png') no-repeat left 20px center, linear-gradient(to bottom, #dedede 0%, #ababab 100%);
			}
		}
	}
	&__inner {
		display: none;
	}
	&__article {
		position: relative;
		padding-left: 58px;
		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 31px;
			bottom: 0;
			width: 1px;
			background-color: red;
		}
	}
	&__title {
		font-weight: 400;
		color: #000000;
		margin-bottom: 0;
	}
	&__text {
		font-weight: 300;
		color: #000000;
	}
	&__close {
		text-decoration: none;
		color: #858585;
		font-size: rem(14px);
		font-weight: 400;
		margin-bottom: 10px;
		display: block;
		padding-left: 28px;
		&:hover {
			color: #d0d0ce;
		}
	}
}

.news-1 {
	.title {
		& > h1 {
			max-width: 70.17921%;
		}
	}
	.news__date {
		margin-bottom: 20px;
	}
	.news__title {
		margin-bottom: 30px;
	}
	.news__descr {
		margin-bottom: 30px;
	}
	.news__imgs {
		float: left;
		margin-right: 40px;
		max-width: calc(300/1008*100%);
	}
	.news__img {
		&_1 {
			margin-bottom: 26px;
		}
		&_2 {
			margin-bottom: 22px;
		}
	}
}

.text-block {
	&__title {
		font-size: rem(20px);
		font-weight: 400;
		display: inline-block;
		vertical-align: top;
		border-bottom: 1px solid #000000;
	}
	&__text {
		font-weight: 400;
		font-size: rem(16px);
	}
	&__img {
		margin-bottom: 16px;
		& > img {
			display: block;
			max-width: 100%;
			height: auto;
		}
	}
}

.range-slider {
	padding: 0 18px;
	&__display {
		background-color: #c5c5c5;
		border-radius: 0;
		&.ui-slider {
			height: 5px;
			border: none;
			margin: 0 8px 13px 11px;
		}
		.ui-slider-range {
			background-color: #c5c5c5;
		}
		.ui-state-default {
			&:nth-of-type(1) {
				background: url('/img/range-left.png') no-repeat;
			}
			&:nth-of-type(2) {
				background: url('/img/range-right.png') no-repeat;
			}
			border: none;
			border-radius: 0;
			outline: none;
			width: 15px;
			height: 15px;
		}
	}
	&__controls {
		display: flex;
		justify-content: space-between;
		margin-bottom: 30px;
	}
	&__from,
	&__to {
		width: 66px;
		border-radius: 5px;
		box-sizing: border-box;
		outline: none;
		border: 1px solid #c5c5c5;
		line-height: rem(20px);
		padding: 0 5px;
		text-align: left;
	}
	&__to {
		text-align: right;
	}
}

.faq {
	.shadow {
		padding: 20px 20px 40px 40px;
	}
	&__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 30px;
	}
	.title {
		width: calc(650/1140*100%);
		h1 {
			max-width: 60%;
		}
	}
	.search {
		width: calc(465/1140*100%);
		&__input {
			&_search {
				font-size: rem(16px);
				font-weight: 600;
				color: #000;
			}
			&_submit {
				@extend .search__input_submit;
			}
		}
	}
}

.file {
	position: relative;
	&__input {
		position: absolute;
		left: -9999px;
	}
	&__label {
		display: block;
		font-size: rem(14px);
		line-height: rem(30px);
		background: linear-gradient(to bottom,#dedede 0%,#ababab 100%);
		border-radius: 3px;
		padding: 0 25px;
		box-shadow: 0 2px 7px 0 rgba(0,0,0,.17);
		cursor: pointer;
	}
	&__span {
		display: block;
		color: #ff0000;
		font-size: rem(12px);
		text-align: center;
		position: absolute;
		width: 100%;
		top: 110%;
	}
}

.conditions {
	&__label {
		display: block;
		padding-left: 35px;
		position: relative;
		cursor: pointer;
		font-size: rem(12px);
		&:before {
			content: '';
			display: block;
			width: 15px;
			height: 15px;
			position: absolute;
			top: 3px;
			left: 0;
			border: 1px solid #000000;
		}
	}
	&__input {
		position: absolute;
		left: -9999px;
		&:checked {
			& + .conditions__label {
				&:before {
					background-image: url('/img/checked.png');
					background-repeat: no-repeat;
					background-position: center;
					background-size: contain;
				}
			}
		}
	}
	&__link {
		color: rgb(0,0,238);
	}
}

.answer {
	width: calc(570/1120*100%);
	margin: 0 auto;
	&__title {
		text-align: center;
		text-transform: uppercase;
		font-weight: 700;
		font-size: rem(30px);
		margin-bottom: 15px;
		line-height: rem(35px);
	}
	&__text {
		margin-bottom: 20px;
		font-weight: 700;
		font-size: rem(18px);
		text-align: center;
		padding-bottom: 30px;
		position: relative;
		&:before {
			content: '';
			display: block;
			background-color: #b1b1b1;
			height: 1px;
			width: 80%;
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			margin: auto;
		}
	}
	&__footer {
		text-align: center;
		font-size: rem(16px);
		max-width: 350px;
		margin: 0 auto;
	}
}

.invalid {
	border-color: #ff0000;
}

input::-webkit-search-decoration,
input::-webkit-search-cancel-button {
	display: none;
}

@-moz-document url-prefix() {

}

@media screen and (-ms-high-contrast: active),
	(-ms-high-contrast: none) {

}